<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :total-items="totalItems"
          :headers="headers"
          :search="search"
          :is-loading="isLoadingData"
          title="Pelanggaran Siswa"
          subtitle="Siswa yang melakukan pelanggaran"
          :items="studentViolations"
          add-btn="Tambah Data"
          :total-pages.sync="totalPages"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @delete-item="confirmDestroy"
          @page="page"
          @change-page="paginationHandler"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!formValid"
      :header="dialog ==='add'? 'Tambah Pelanggaran Siswa': 'Edit Pelanggaran Siswa'"
      :text-button="dialog ==='add'? 'Tambah': 'Update'"
      @close-button="modalDialog = false"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
    >
      <template v-slot:body>
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-menu
                v-model="date_menu"
                :close-on-content-click="true"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="studentViolation.violation_date"
                    label="Tanggal"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    clearable
                    v-on="on"
                    @click="autofillDate"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="studentViolation.violation_date"
                  :first-day-of-week="0"
                  locale="id-id"
                  color="primary"
                  @input="date_menu = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-autocomplete
                v-model="studentViolation.student_uuid"
                label="Nama Siswa"
                dense
                outlined
                :items="students"
                item-text="name"
                item-value="uuid"
                clearable
              ></v-autocomplete>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-autocomplete
                v-model="studentViolation.violation_uuid"
                label="Pelanggaran"
                dense
                outlined
                :items="violations"
                item-text="name"
                item-value="uuid"
                clearable
                @change="test($event)"
              ></v-autocomplete>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-textarea
                v-model="studentViolation.description"
                label="Deskripsi"
                dense
                outlined
              ></v-textarea>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="studentViolation.follow_up"
                label="Tindak Lanjut"
                dense
                outlined
              ></v-text-field>
            </div>
          </v-col>
        </v-row>
      </template>
    </ModalDialog>

    <ConfirmDialog
      :visible="confirmDialog"
      header="Hapus Pelanggaran Siswa"
      :is-loading="isLoadingButton"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'

export default {
  name: 'ViolationStudent',
  components: {
    DataTablePagination,
    ModalDialog,
    MainCard,
    SkeletonLoaderTable,
    ConfirmDialog,
  },
  data() {
    return {
      page: 1,
      search: '',
      totalItems: 0,
      totalPages: 0,
      width: 600,
      modalDialog: false,
      confirmDialog: false,
      dialog: null,
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Tanggal', value: 'violation_date' },
        { text: 'Nama Siswa', value: 'student.full_name' },
        { text: 'Pelanggaran', value: 'violation.name' },
        { text: 'Deskripsi', value: 'description' },
        { text: 'Poin', value: 'violation.point' },
        { text: 'Tindak Lanjut', value: 'follow_up' },
        { text: 'Aksi', value: 'actions' },
      ],
      students: [],
      violations: [],
      studentViolations: [],
      studentViolation: {
        uuid: '',
        student_uuid: '',
        violation_uuid: '',
        school_uuid: '',
        employee_uuid: '',
        violation_date: '',
        description: '',
        follow_up: '',
      },
      date_menu: false,
      isLoadingTable: true,
      isLoadingData: false,
      isLoadingButton: false,
      school: '',
      school_uuid: '',
      user_uuid: '',
      filterQuery: {
        search: '',
      },
      formValid: false,
    }
  },
  watch: {
    studentViolation: {
      handler() {
        const valid = []
        const requiredField = ['violation_date', 'student_uuid', 'violation_uuid', 'description', 'follow_up']
        Object.entries(this.studentViolation).forEach(([key, item]) => {
          if (requiredField.includes(key)) valid.push(!!(item && item.length))
        })
        this.formValid = !valid.includes(false)
      },
      deep: true,
    },
    page: {
      handler() {
        this.listViolationStudent(this.filterQuery)
      },
    },
  },
  async mounted() {
    this.isLoadingTable = true
    this.getSchoolUuid()
    await this.listViolationStudent()
    await this.listStudents()
    await this.listViolation()
    this.isLoadingTable = false
  },
  methods: {
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: "You don't have access to school",
            color: 'error',
          })

          return
        }
        this.school_uuid = el.school.uuid
      })

      return true
    },

    async listStudents(params = {}) {
      await this.$services.ApiServices.list('student', {
        ...params,
        school_uuid: this.school_uuid,
        page: this.page,
      }).then(
        ({ data }) => {
          this.isLoadingData = true
          this.students = data.data
          this.isLoadingData = false
        },
        err => {
          console.error(err)
        },
      )
    },

    async listViolation(params = {}) {
      await this.$services.ApiServices.list('violation', {
        ...params,
        school_uuid: this.school_uuid,
        per_page: 'all',
      }).then(
        ({ data }) => {
          this.isLoadingData = true
          this.violations = data.data
          this.isLoadingData = false
        },
        err => {
          console.error(err)
        },
      )
    },

    async listViolationStudent(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list('violationstudent', {
        ...params,
        school_uuid: this.school_uuid,
        page: this.page,
      }).then(
        ({ data }) => {
          this.studentViolations = data.data.map((studentViolations, index) => ({
            ...studentViolations,
            index: index + 1,
          }))
          this.totalPages = data.meta.last_page
          this.totalItems = data.meta.total
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },

    resetForm() {
      this.studentViolation.uuid = ''
      this.studentViolation.student_uuid = ''
      this.studentViolation.violation_uuid = ''
      this.studentViolation.violation_date = ''
      this.studentViolation.description = ''
      this.studentViolation.follow_up = ''
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },

    showFormAdd() {
      this.dialog = 'add'
      this.resetForm()
      this.modalDialog = true
    },

    async add() {
      this.isLoadingButton = true
      if (JSON.parse(localStorage.getItem('user')).user.role === 'school') {
        this.studentViolation.school_uuid = this.school_uuid
        delete this.studentViolation.employee_uuid
      } else if (JSON.parse(localStorage.getItem('user')).user.details[0].status_work === 'Guru BK') {
        this.studentViolation.school_uuid = this.school_uuid
        this.studentViolation.employee_uuid = JSON.parse(localStorage.getItem('user')).user.details[0].uuid
      }
      await this.$services.ApiServices.add('violationstudent', this.studentViolation).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.listViolationStudent(this.filterQuery)
          this.resetForm()
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.isLoadingButton = false
    },

    async showFormEdit(uuid) {
      this.dialog = 'edit'
      await this.$services.ApiServices.get('violationstudent', uuid).then(
        ({ data }) => {
          this.studentViolation.name = data.data.name
          this.studentViolation.follow_up = data.data.follow_up
          this.studentViolation.description = data.data.description
          this.studentViolation.student_uuid = data.data.student.uuid
          this.studentViolation.violation_uuid = data.data.violation.uuid
          this.studentViolation.violation_date = data.data.violation_date
        },
        err => console.error(err),
      )
      this.modalDialog = true
    },

    async update() {
      this.isLoadingButton = true
      await this.$services.ApiServices.update(
        'violationstudent',
        this.studentViolation,
        this.studentViolation.uuid,
      ).then(
        ({ data }) => {
          this.listViolationStudent(this.filterQuery)
          this.resetForm()
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.isLoadingButton = false
    },

    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.studentViolation.uuid = uuid
    },

    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy('violationstudent', this.studentViolation.uuid).then(
        ({ data }) => {
          this.listViolationStudent(this.filterQuery)
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => {
          this.isLoadingButton = false
          this.confirmDialog = false
          console.error(err)
        },
      )
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    searchHandler(search) {
      this.filterQuery.search = search
      this.page = 1
      this.listViolationStudent(this.filterQuery)
    },

    autofillDate() {
      this.studentViolation.violation_date = new Date().toISOString().substr(0, 10)
    },
  },
}
</script>

<style scoped>
.v-menu__content::v-deep {
  min-width: 0 !important;
}
</style>

<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :page="page"
          :total-items="totalItems"
          :headers="headers"
          :items="violationPenalties"
          :search="search"
          :is-loading="isLoadingData"
          title="Sanksi Pelanggaran"
          subtitle="Halaman untuk menambahkan pelanggaran baru"
          add-btn="Tambah Data"
          :total-pages.sync="totalPages"
          @add-item="showFormAdd"
          @edit-item="showFormEdit"
          @delete-item="confirmDestroy"
          @page="page"
          @change-page="paginationHandler"
          @live-search="searchHandler"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      :width="width"
      :is-loading-button="isLoadingButton"
      :is-disabled-button="!violationPenalty.point || !violationPenalty.description"
      :dialog="dialog"
      :header="dialog === 'add' ? 'Tambah Sanksi Pelanggaran': 'Edit Sanksi Pelanggaran'"
      :text-button="dialog === 'add' ? 'Tambah': 'Update'"
      @handler-button="handlerButton"
      @close-modal="modalDialog = false"
      @close-button="modalDialog = false"
    >
      <template v-slot:body>
        <div>
          <v-text-field
            v-model="violationPenalty.point"
            label="Poin"
            dense
            type="number"
            outlined
          ></v-text-field>
        </div>
        <div>
          <v-text-field
            v-model="violationPenalty.description"
            label="Deskripsi"
            dense
            type="text"
            outlined
          ></v-text-field>
        </div>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Pelanggaran"
      @close-button="confirmDialog = false"
      @destroy-button="destroy"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'

export default {
  name: 'ViolationPenalty',
  components: {
    DataTablePagination,
    ModalDialog,
    ConfirmDialog,
    SkeletonLoaderTable,
    MainCard,
  },
  data() {
    return {
      skeleton: {
        class: 'mb-6',
        boilerplate: false,
        elevation: 5,
        types: {},
      },
      page: 1,
      totalItems: 0,
      totalPages: 0,
      dialog: null,
      search: '',
      isLoadingTable: true,
      isLoadingButton: false,
      isLoadingData: false,
      modalDialog: false,
      confirmDialog: false,
      width: 600,
      service: 'violationsetting',
      violationPenalties: [],
      violationPenalty: {
        school_uuid: '',
        description: '',
        point: '',
      },
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Poin', value: 'point' },
        { text: 'Deskripsi', value: 'description' },
        { text: 'Aksi', value: 'actions' },
      ],
      school: '',
      school_uuid: '',
      user_uuid: '',
      filterQuery: {
        search: '',
      },
      formValid: false,
    }
  },
  watch: {
    page: {
      handler() {
        this.listViolationPenalty(this.filterQuery)
      },
    },
  },
  async mounted() {
    await this.getSchoolUuid()
    this.isLoadingTable = true
    await this.listViolationPenalty()
    this.isLoadingTable = false
  },
  methods: {
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.violationPenalty.school_uuid = el.uuid
          this.school_uuid = el.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: "You don't have access to school",
            color: 'error',
          })

          return
        }
        this.violationPenalty.school_uuid = el.school.uuid
      })

      return true
    },

    async listViolationPenalty(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.list(this.service, {
        ...params,
        school_uuid: this.school_uuid,
        page: this.page,
      }).then(
        ({ data }) => {
          this.violationPenalties = data.data.map((violationPenalties, index) => ({
            ...violationPenalties,
            index: index + 1,
          }))
          this.totalPages = data.meta.last_page
          this.totalItems = data.meta.total
        },
        err => {
          console.error(err)
        },
      )
      this.isLoadingTable = false
      this.isLoadingData = false
    },

    resetForm() {
      this.violationPenalty.description = ''
      this.violationPenalty.point = ''
    },

    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },

    showFormAdd() {
      this.dialog = 'add'
      this.resetForm()
      this.modalDialog = true
    },

    async add() {
      this.isLoadingButton = true
      await this.$services.ApiServices.add(this.service, this.violationPenalty).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.isLoadingButton = false
          this.modalDialog = false
          this.resetForm()
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      await this.listViolationPenalty(this.filterQuery)
    },

    async showFormEdit(uuid) {
      this.dialog = 'edit'
      this.violationPenalty.uuid = uuid
      await this.$services.ApiServices.get(this.service, uuid).then(
        ({ data }) => {
          this.violationPenalty.school_uuid = data.data.school.uuid
          this.violationPenalty.point = data.data.point
          this.violationPenalty.description = data.data.description
        },
        err => console.error(err),
      )
      this.modalDialog = true
    },

    async update() {
      this.isLoadingButton = true
      await this.$services.ApiServices.update(this.service, this.violationPenalty, this.violationPenalty.uuid).then(
        ({ data }) => {
          this.listViolationPenalty(this.filterQuery)
          this.resetForm()
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.modalDialog = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
      this.isLoadingButton = false
    },

    confirmDestroy(uuid) {
      this.confirmDialog = true
      this.violationPenalty.uuid = uuid
    },

    async destroy() {
      this.isLoadingButton = true
      await this.$services.ApiServices.destroy(this.service, this.violationPenalty.uuid).then(({ data }) => {
        this.listViolationPenalty(this.filterQuery)
        this.showSnackbar({
          text: data.result.message,
          color: 'success',
        })
      })
      this.isLoadingButton = false
      this.confirmDialog = false
    },
    handlerButton() {
      if (this.dialog === 'add') this.add()
      else this.update()
    },

    paginationHandler(pageNumber) {
      this.page = pageNumber
    },

    searchHandler(search) {
      this.filterQuery.search = search
      this.page = 1
      this.listViolationPenalty(this.filterQuery)
    },
  },
}
</script>

<style>
</style>

<template>
  <div class="mt-8">
    <v-tabs
      v-model="tab"
      show-arrows
      fixed-tabs
    >
      <v-tab
        v-for="(tabItem,index) in tabs"
        :key="tabItem.icon"
        @click="reRenderComponent(index)"
      >
        <span>{{ tabItem.title }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <violation-type :key="componentKey"></violation-type>
      </v-tab-item>
      <v-tab-item>
        <violation-penalty :key="componentKey1"></violation-penalty>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ViolationPenalty from './ViolationPenalty.vue'
import ViolationType from './ViolationType.vue'

export default {
  name: 'Violation',
  components: {
    ViolationPenalty,
    ViolationType,
  },
  data() {
    return {
      tab: '',
      componentKey: 0,
      componentKey1: 0,
      tabs: [{ title: 'Jenis Pelanggaran' }, { title: 'Sanksi Pelanggaran' }],
    }
  },
  mounted() {},
  methods: {
    reRenderComponent(index) {
      switch (index) {
        case 0:
          this.componentKey += 1
          break
        case 1:
          this.componentKey1 += 1
          break
        default:
          break
      }
    },
  },
}
</script>

<style>
</style>
